import Fuse from "fuse.js"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import TextTruncate from "react-text-truncate"
import Truncate from "react-truncate-html"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpPost.nodes
  const pageContent = data.allWpPage.nodes

  const [searchTerm, setSearchterm] = useState("")
  const [filteredPosts, setFilteredPosts] = useState(posts)
  const [filtered, setFiltered] = useState(false)

  const searchOptions = {
    // isCaseSensitive: false,
    // includeScore: false,
    // shouldSort: true,
    // includeMatches: false,
    findAllMatches: false,
    // minMatchCharLength: 1,
    // location: 0,
    threshold: 0.1,
    // distance: 100,
    useExtendedSearch: false,
    ignoreLocation: true,
    // ignoreFieldNorm: false,
    keys: ["title", "excerpt", "tags.nodes.name"],
  }

  const fuse = new Fuse(posts, searchOptions)

  const onSearchChange = e => {
    setSearchterm(e.target.value)
  }

  const onBlogSearch = () => {
    if (searchTerm !== "") {
      setFilteredPosts(fuse.search(searchTerm))
      setFiltered(true)
    } else {
      setFilteredPosts(posts)
      setFiltered(false)
    }
  }

  return (
    <div>
      <Seo
        title={
          pageContent.seo?.title
            ? pageContent.seo.title
            : "Careers and Business Advice"
        }
        description={pageContent.seo?.metaDesc}
      />

      <Layout>
        <section className="header_section">
          <div className="container">
            <div className="header_section__content">
              <h1>Blog</h1>
              <div className="header_section__content_sub">
                <div>The Expert Recruiters in Medical Aesthetics </div>
              </div>
              <div className="header_section__content_btn text-center">
                <a href="#content">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="bounce"
                    width="109.491"
                    height="57.574"
                    viewBox="0 0 109.491 57.574"
                  >
                    <path
                      id="Path_5"
                      data-name="Path 5"
                      d="M-847.3,2972.583h0l53.331,53.331-53.331,53.332"
                      transform="translate(3080.66 848.711) rotate(90)"
                      fill="none"
                      stroke="rgba(53,51,67,0.5)"
                      strokeWidth="4"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className="header_section__image">
              <div className="header-container--standars">
                <StaticImage
                  src="../../static/assets/images/header/image_4.svg"
                  placeholder="blurred"
                  alt="Imgage 0002"
                />
              </div>
            </div>
          </div>
          <div className="standard_header_path">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              viewBox="0 0 1924.003 706.339"
            >
              <path
                id="Path_1"
                data-name="Path 1"
                d="M.5,1210.979h1924V504.64S1271.73,915.921,929.1,801.894.5,1037.8.5,1037.8Z"
                transform="translate(-0.5 -504.64)"
                fill="#ecebe4"
              />
            </svg>
          </div>
        </section>

        <section className="intro_text">
          <div className="container" id="content">
            <div className="section__title text-center">
              Aesthetics Careers and Business Advice
            </div>

            <div className="intro_text_holder">
              <div
                dangerouslySetInnerHTML={{
                  __html: pageContent[0].content,
                }}
              />
            </div>
          </div>
        </section>

        <section className="search_block">
          <div className="container">
            <form
              className="search_block__form"
              onSubmit={e => e.preventDefault()}
            >
              <div className="search_block__field">
                <input
                  type="text"
                  onChange={e => onSearchChange(e)}
                  placeholder="Enter Keyword"
                />
                <img src="/assets/images/icons/search.svg" alt="Search" />
              </div>
              <div className="search_block__button">
                <a
                  onClick={onBlogSearch}
                  className="waves-effect waves-light btn"
                >
                  SEARCH
                </a>
              </div>
            </form>
          </div>
        </section>

        <section className="blog_posts">
          <div className="container">
            <div className="blog_posts__holder">
              {!filteredPosts.length && (
                <p
                  style={{
                    fontSize: "1.9rem",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    margin: "60px 0",
                  }}
                >
                  No blog posts to show.
                </p>
              )}
              {filteredPosts.map(post => {
                const title = filtered ? post.item.title : post.title
                const postDate = filtered ? post.item.date : post.date
                const postUri = filtered ? post.item.uri : post.uri
                const postExcerpt = filtered ? post.item.excerpt : post.excerpt
                const postThumbnail = filtered
                  ? getImage(post.item.blog.thumbnail.localFile)
                  : getImage(post.blog.thumbnail.localFile)
                const postThumbnailSrc = filtered
                  ? post.item.blog.thumbnail
                  : post.blog.thumbnail
                const postAltTxt = filtered
                  ? post.item.blog.thumbnail.altText
                  : post.blog.thumbnail.altText
                const postTags = filtered
                  ? post.item.tags.nodes
                  : post.tags.nodes
                const featuredImg = filtered
                  ? post.item.featuredImage.node.localFile
                  : post.featuredImage.node.localFile

                let thumb,
                  thumbAlt = ""
                thumbAlt = postAltTxt ? postAltTxt : title
                if (postThumbnailSrc) {
                  thumb = postThumbnail
                } else if (post.featuredImage.node.localFile) {
                  thumb = featuredImg
                } else {
                  thumb = "/assets/images/blog_thumb.svg"
                }

                return (
                  <article
                    itemScope
                    itemType="http://schema.org/Article"
                    className="blog_posts__post"
                    key={postUri}
                  >
                    <div className="blog_posts__post_img">
                      <a href={postUri}>
                        <GatsbyImage
                          image={thumb}
                          alt={thumbAlt}
                          title={thumbAlt}
                        />
                      </a>
                    </div>
                    <div className="blog_posts__post_tags">
                      {postTags.map(tag => {
                        return (
                          <span key={tag.id}>
                            <a href={tag.uri}>{tag.name}</a>
                            <span>,</span>{" "}
                          </span>
                        )
                      })}
                    </div>
                    <div className="blog_posts__post_title underline_heading putty">
                      <span itemProp="headline">
                        <TextTruncate line={4} breakWord="…" text={title} />
                      </span>
                    </div>
                    <div
                      className="blog_posts__post_desc"
                      itemProp="description"
                    >
                      <Truncate
                        line={9}
                        breakWord="…"
                        dangerouslySetInnerHTML={{
                          __html: postExcerpt,
                        }}
                      />
                    </div>
                    <div className="blog_posts__post_btn">
                      <Link to={postUri} itemProp="url" className="btn">
                        READ MORE
                      </Link>
                    </div>
                  </article>
                )
              })}
            </div>
          </div>
        </section>
      </Layout>

      {previousPagePath && (
        <>
          <Link to={previousPagePath}>Previous page</Link>
          <br />
        </>
      )}
      {nextPagePath && <Link to={nextPagePath}>Next page</Link>}
    </div>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
        blog {
          thumbnail {
            description
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
        tags {
          nodes {
            id
            name
            uri
          }
        }
      }
    }
    allWpPage(filter: { uri: { eq: "/blog/" } }) {
      nodes {
        uri
        title
        content
        seo {
          metaDesc
          title
        }
      }
    }
  }
`
